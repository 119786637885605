import { Map } from './components/Map.js'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Map showCollisionBoxes={false} />}></Route>
				<Route path=':z1/:lat1/:lng1' element={<Map showCollisionBoxes={false} />}></Route>
			</Routes>
		</Router>
	)
}

export default App
