import 'maplibre-gl/dist/maplibre-gl.css'
import './Map.css'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { MaplibreLegendControl } from '@watergis/maplibre-gl-legend'
import MapGL, { GeolocateControl, NavigationControl, ScaleControl, useControl } from 'react-map-gl/maplibre'

const LegendControl = () => {
	useControl(() => new MaplibreLegendControl({}, {}), { position: 'top-left' })
	return null
}

function Map() {
	const mapRef = useRef()

	const [showCollisions, setShowCollisions] = useState(false)

	let { z1, lat1, lng1 } = useParams()

	const [viewState, setViewState] = React.useState({
		longitude: lng1 || 20.253165,
		latitude: lat1 || 63.567775,
		zoom: z1 || 3.5,
		bearing: 0,
		pitch: 0,
	})

	const navigate = useNavigate()

	useEffect(() => {
		navigate(`../${viewState.zoom}/${viewState.latitude}/${viewState.longitude}`, { replace: true })
	}, [viewState])

	useEffect(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			map.showCollisionBoxes = showCollisions
		}
	}, [showCollisions])

	return (
		<MapGL
			{...viewState}
			onMove={(evt) => setViewState(evt.viewState)}
			mapStyle={process.env.REACT_APP_OSM_LIBERTY}
			style={{ width: '100%', height: '100%' }}
			ref={mapRef}
		>
			<GeolocateControl position='bottom-right' />
			<NavigationControl position='bottom-right' />
			<ScaleControl />
			<LegendControl />
			<div
				style={{
					position: 'absolute',
					right: 0,
					top: '10px',
					fontSize: '18px',
					display: 'flex',
					alignItems: 'center',
					background: 'white',
					padding: '4px',
				}}
			>
				<input
					type='checkbox'
					checked={showCollisions}
					onChange={() => setShowCollisions(!showCollisions)}
					style={{
						width: '20px',
						height: '20px',
					}}
				/>
				ShowCollisionBoxes
			</div>
		</MapGL>
	)
}

export { Map }
